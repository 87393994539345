<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ''"  class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"            
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
        </div>

        <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="50vw"
            :visible="showFilter"
            @on-item-search="
              showFilter = false;
              reset();
            "
            @on-item-reset="
              showFilter = false;
              reset(true);
            "
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-1">
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.vendor_id"
                  :busy="busy"
                  label="Vendor ID"
                  :hasClear="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.vendor_name"
                  :busy="busy"
                  label="Name"
                  :hasClear="true"
                />
              </v-col> 
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.active"
                  :busy="busy"
                  label="Active"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.global"
                  :busy="busy"
                  label="Global"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.include_by_default"
                  :busy="busy"
                  label="Include by Default"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.account_id"
                  :busy="busy"
                  label="Account"
                  :items="accounts"
                  :multiple="true" 
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.global_account_id"
                  :busy="busy"
                  label="Global Account"
                  :items="accounts"
                  :multiple="true" 
                />
              </v-col> 
            </v-row>
          </o-drawer>
      </v-card-title>
      <v-divider v-if="action === ''" />

      <v-card-text v-if="action === ''" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="[
            {
              text: '#',
              align: 'end',
              sortable: false,
              value: 'row_no',
              type: 'int',
            },
            {
              text: 'Account',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'vendor_name',
              type: 'slot',
            },
            {
              text: 'Active',
              align: 'center',
              sortable: false,
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Global',
              align: 'center',
              sortable: false,
              value: 'global',
              type: 'boolean',
            },
            {
              text: 'Include by Default',
              align: 'center',
              sortable: false,
              value: 'include_by_default',
              type: 'boolean',
            },
            {
              text: 'Type',
              align: 'start',
              sortable: false,
              value: 'fee_type',
              type: 'text',
            },
            {
              text: 'Currency',
              align: 'start',
              sortable: false,
              value: 'currency',
              type: 'text',
            },
            {
              text: 'Amount',
              align: 'start',
              sortable: false,
              value: 'fee_amount',
              type: 'decimal',
            },
            {
              text: 'Notes',
              align: 'start',
              sortable: false,
              value: 'notes',
              type: 'text',
            },
            {
              text: 'Created Date',
              align: 'start',
              value: 'created_at',
              type: 'date-time',
            },
            {
              text: 'Created By',
              align: 'start',
              value: 'created_by_name',
              type: 'slot',
            },
            {
              value: 'action',
              type: 'action',
              sortable: false,
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Edit',
                  icon: 'mdi-pencil-box-outline',
                  color: 'success',
                },
                {
                  key: 'on-item-delete',
                  label: 'Delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'danger',
                },
              ],
            },
          ]"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'vendor_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.vendor_name }} ({{ row.prop.item.vendor_id }})
            </div>
            <div
              v-if="row.prop.key === 'created_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'deleted_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ''" />

      <div v-if="action !== ''" class="ml-5 pb-5 mr-5">
        <v-row class="o-container-content">
          <v-col cols="12" class="pa-10 pr-15">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <o-input
                    v-model="model.vendor_name"
                    :busy="busy"
                    label="Vendor Name"
                    :is-required="true"
                    :rules="[(v) => !!v || 'This is required']"
                  />
                </v-col>

                <v-col cols="12">
                  <o-combo-box
                    v-model="model.account_id"
                    :busy="true"
                    label="Account"
                    :items="accounts"
                  />
                </v-col>

                <v-col cols="6">
                  <o-combo-box
                    v-model="model.fee_type"
                    :busy="busy"
                    :has-id="false"
                    label="Fee Type"
                    :items="[
                      { id: 'CPM', name: 'CPM' },
                      { id: 'Percent', name: 'Percent' },
                    ]"
                  />
                </v-col>

                <v-col cols="6">
                  <o-input
                    v-model="model.fee_amount"
                    :busy="busy"
                    label="Fee Amount"
                    :is-required="true"
                  />
                </v-col>

                <v-col cols="12">
                  <o-input
                    v-model="model.currency"
                    :busy="busy"
                    label="Currency"
                    :is-required="true"
                  />
                </v-col>

                <v-col cols="12">
                  <o-check-box
                    v-model="model.global"
                    :busy="busy"
                    label="Global"
                    style="display:inline-block"
                  />

                  <o-check-box
                    v-model="model.active"
                    :busy="busy"
                    label="Active"
                    style="display:inline-block"
                  />

                  <o-check-box
                    v-model="model.include_by_default"
                    :busy="busy"
                    label="Include by Default"
                    style="display:inline-block"
                  />
                </v-col>

                <v-col cols="12">
                  <o-input
                    v-model="model.notes"
                    :busy="busy"
                    label="Notes"
                    :is-required="true"
                    :rules="[(v) => !!v || 'This is required']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "ManageVendor",
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Vendors",
  },
  data() {
    return {
      title: "Vendors",
      busy: false,
      action: "",
      parameters: {
        vendor_id: "",
        vendor_name: "",
        account_id: [],
        fee_type: [],
        global: [],
        active: [],
        include_by_default: [],
        global_account_id: []
      },
      vendors: [],
      accounts: [],
      valid: false,
      baseModel: {
        id: 0,
        vendor_name: "",
        fee_type: "",
        fee_amount: 0,
        global: false,
        active: false,
        account_id: 0,
        notes: "",
        global_account_id: 0,
        currency: "USD",
        created_by: 0,
        updated_by: 0,
        created_at: null,
        updated_at: null,
        vendor_id: 0,
        include_by_default: false,
      },
      originalModel: {},
      model: {},
      permissions: [],
      permissionData: [],
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  created() {
    this.listParameters.fields = [
      "ROW_NUMBER() OVER(ORDER BY a.deleted_at DESC, a.updated_at DESC) row_no",
      "a.*",
      "b.account_name",
      "concat(c.last_name, ', ', c.first_name) as created_by_name",
    ];
    this.listParameters.sources = {
      main: "vendors a",
      children: [
        {
          name: "accounts b",
          on: "a.account_id = b.account_id",
        },
        {
          name: "users c",
          on: "a.created_by = c.id",
        },
      ],
    };
    this.listParameters.orders = [
      {
        sort: "a.deleted_at",
        dir: "DESC",
      },
      {
        sort: "a.updated_at",
        dir: "DESC",
      },
    ];
  },
  async mounted() {
    this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
  },
  methods: {
    add() {
      this.action = "add";
      this.model = _.cloneDeep(this.baseModel);
      this.model.account_id = 2;
      this.model.fee_type = "CPM";
      this.model.active = true;
      this.model.currency = "USD";
    },
    edit(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);
      this.model.password = "";
      this.model.confirm_password = "";
      this.action = "edit";
    },
    async remove(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are deleting <strong>${this.model.first_name} ${this.model.last_name} (${this.model.email})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            this.model.deleted_by = this.loggedInUser.id;
            await this.deleteData(`/rest/users/${this.model.id}`, null)
              .then((response) => {
                this.$refs.container.snackBar(
                  `
                The record of ${this.model.first_name} ${this.model.last_name} (${this.model.email}) was successfully deleted.
                `,
                  "red",
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                errors.push(err.data.message);
                this.model = this.baseModel;
                this.originalModel = this.baseModel;
              });
          }
        });
    },
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = "/rest/vendors";

      this.model.account_id = this.loggedInUser.account_id;
      this.model.platform_id = this.loggedInUser.platform_id;

      if (this.action === "add") {
        this.model.created_by = this.loggedInUser.id;
        await this.postData(url, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully added ${response.data.vendor_id} (${response.data.vendor_id})
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {
        const model = _.cloneDeep(this.model);
        if (model.vendor_name === this.originalModel.vendor_name) {
          delete this.model.vendor_name;
        }
        if (this.model.created_by < 1) {
          this.model.created_by = this.loggedInUser.id;
        }
        this.model.updated_by = this.loggedInUser.id;
        await this.putData(`${url}/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully updated ${model.vendor_name} (${model.vendor_id}).
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `
                Error updating item: ${errors.join(",")}
                `,
          "red",
          2000
        );
        return false;
      } else {
        this.reset();
      }
    },
   reset(par) {
      if (par) {
        this.parameters = {
          vendor_id: "",
          vendor_name: "",
          account_id: [],
          fee_type: [],
          global: [],
          active: [],
          include_by_default: [],
          global_account_id: []
        }
      }

      this.listParameters.wheres = [];
      const wheres = [];      
      if (this.parameters.role_id != '') {
         wheres.push(`a.vendor_id::text ilike '%${this.parameters.role_id}%'`)
      }
      if (this.parameters.vendor_name != '') {
         wheres.push(`a.vendor_name ilike '%${this.parameters.vendor_name}%'`)
      }
      if (this.parameters.account_id.length > 0) {
         wheres.push(`a.account_id in (${this.parameters.account_id.join(',')})`)
      }

      if (this.parameters.fee_type.length > 0) {
         wheres.push(`a.fee_type in (${this.parameters.fee_type.join(',')})`)
      } 
      if (this.parameters.active.length > 0) {
         wheres.push(`a.active in (${this.parameters.active.join(',')})`)
      } 
      if (this.parameters.include_by_default.length > 0) {
         wheres.push(`a.include_by_default in (${this.parameters.include_by_default.join(',')})`)
      } 
      if (this.parameters.global_account_id.length > 0) {
         wheres.push(`a.global_account_id in (${this.parameters.global_account_id.join(',')})`)
      } 
      if (wheres.length > 0) this.listParameters.wheres = [wheres.join(' OR ')];

      this.action = "";
      this.model = _.cloneDeep(this.baseModel);
      this.originalModel = _.cloneDeep(this.baseModel);
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
