<template>
  <ManageVendor dialog-name="Vendor" />
</template>
<script>
import ManageVendor from '@/views/app/manage/Vendor'

export default {
  name: 'VendorWrapper',
  components: {
    ManageVendor
  }
}
</script>
